/* Checkout Container */
.checkout-container {
  max-width: 1000px;
  margin: 40px auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.page-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  color: #2c3e50;
  font-weight: 600;
}

/* Loader Styling */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  text-align: center;
}

/* Card Section for Form Sections */
.card-section {
  background: #ffffff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.card-section h3 {
  font-size: 20px;
  color: #2c3e50;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Form Group Styling */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 12px 15px;
  border: 1.5px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
  outline: none;
}

/* Dropshipping Section */
.dropshipping-section {
  background: #ffe6e6;
}

/* Submit Button Styling */
.btn-submit {
  padding: 15px 30px;
  background: linear-gradient(45deg, #3498db, #2980b9);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-submit:hover {
  background: linear-gradient(45deg, #2980b9, #3498db);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(52, 152, 219, 0.3);
}

.btn-submit:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* Responsive Styles for Mobile Screens */
@media (max-width: 767px) {
  .checkout-container {
    margin: 0;
    padding: 15px;
    border-radius: 0;
  }

  .page-title {
    font-size: 24px;
    margin: 15px 0;
  }

  .card-section {
    padding: 15px;
    margin-bottom: 15px;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    padding: 10px;
    font-size: 16px; /* Keep 16px to prevent zoom on iOS */
  }

  .shipping-options label {
    padding: 12px;
    font-size: 14px;
  }

  /* Stack shipping options vertically */
  .shipping-options {
    display: flex;
    flex-direction: column;
  }

  /* Adjust button size for better touch targets */
  .btn-submit {
    padding: 15px;
    font-size: 16px;
    width: 100%;
  }

  /* Improve form spacing on mobile */
  .form-group {
    margin-bottom: 15px;
  }

  /* Make sure inputs are easily tappable */
  input[type="radio"],
  input[type="checkbox"] {
    min-width: 24px;
    min-height: 24px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #007bff;
  margin: 20px 0;
}

.state-dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.invalid-input {
  border-color: #e74c3c !important;
  background-color: #fdf0ed;
}

.error-message {
  color: #e74c3c;
  font-size: 13px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.form-group select {
  margin-bottom: 15px;
}

/* Two Column Layout for Desktop */
@media (min-width: 768px) {
  .delivery-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    max-width: 800px;
    margin: 0 auto;
  }

  /* Add this new style for form groups that should be side by side */
  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  /* Specific fields that should take full width */
  .form-group.full-width {
    grid-column: 1 / -1;
  }

  .card-section {
    margin-bottom: 25px;
  }

  /* Full width sections */
  .card-section.shipping-section {
    grid-column: 1 / -1;
  }

  .btn-submit {
    grid-column: 1 / -1;
    max-width: 400px;
    margin: 20px auto 0;
  }
}

/* Animation for hover states */
@keyframes pulse {
  /* Remove this entire keyframe block */
}

.card-section:hover {
  transform: translateY(-2px);
}

/* Add styles for the shipping dropdown */
.shipping-dropdown {
  width: 100%;
  padding: 12px 15px;
  border: 1.5px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.shipping-dropdown:hover {
  border-color: #3498db;
}

.shipping-dropdown:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
  outline: none;
}

/* Add these new styles */

.order-summary {
  margin-bottom: 30px;
  background: #f8f9fa;
  transition: all 0.3s ease;
}

.order-summary:hover {
  transform: none;
}

.order-summary h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 20px;
}

.summary-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #34495e;
  font-size: 16px;
}

.summary-row.total {
  font-weight: 700;
  font-size: 18px;
  color: #2c3e50;
}

.summary-divider {
  height: 1px;
  background: #e0e0e0;
  margin: 10px 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .order-summary {
    margin: 15px;
    padding: 15px;
  }

  .summary-row {
    font-size: 14px;
  }

  .summary-row.total {
    font-size: 16px;
  }
}

/* Add a subtle animation for the summary */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.order-summary {
  animation: fadeIn 0.3s ease-out;
}