/* Order Details Container */
.order-details-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem;
}

.order-details-title {
  font-size: 28px;
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.main-content {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  gap: 2rem;
  align-items: start;
}

.left-column,
.center-column,
.right-column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Order Summary and Customer Details */
.order-summary p,
.customer-details p {
  margin: 0.5rem 0;
  line-height: 1.6;
}

/* Order Items Table */
.order-items {
  height: 100%;
}

.items-table {
  width: 100%;
  border-collapse: collapse;
}

.items-table th,
.items-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.items-table th {
  background-color: #f8f9fa;
  font-weight: 600;
}

/* Status Controls */
.status-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.status-dropdown {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.btn-update-status,
.btn-submit-shipping,
.btn-view-invoice {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.shipping-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.btn-back {
  display: block;
  margin: 2rem auto 0;
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .main-content {
    grid-template-columns: 1fr 1fr;
  }
  
  .center-column {
    grid-column: span 2;
    order: 3;
  }
}

@media (max-width: 768px) {
  .main-content {
    grid-template-columns: 1fr;
  }
  
  .center-column {
    grid-column: 1;
    order: 0;
  }
  
  .status-controls {
    flex-direction: column;
  }
  
  .card {
    margin-bottom: 1rem;
  }
}

/* Additional mobile responsiveness improvements */
@media (max-width: 768px) {
  .order-details-container {
    padding: 1rem;
  }

  .order-details-title {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .card {
    padding: 1rem;
  }

  /* Improve table readability on mobile */
  .items-table thead {
    display: none; /* Hide table headers on mobile */
  }

  .items-table tbody tr {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.5rem;
    border-bottom: 2px solid #eee;
  }

  .items-table td {
    display: grid;
    grid-template-columns: 120px 1fr;
    padding: 0.5rem;
    border: none;
  }

  .items-table td::before {
    content: attr(data-label);
    font-weight: 600;
    color: #666;
  }

  /* Improve button and input sizing for touch */
  .btn-update-status,
  .btn-submit-shipping,
  .btn-view-invoice,
  .status-dropdown,
  .shipping-input {
    min-height: 44px; /* Better touch target size */
  }

  /* Add spacing between sections */
  .left-column > *,
  .center-column > *,
  .right-column > * {
    margin-bottom: 1rem;
  }

  /* Make address text more readable */
  address {
    white-space: pre-line;
    line-height: 1.5;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .order-details-container {
    padding: 0.5rem;
  }

  .card {
    border-radius: 4px;
  }

  .order-details-title {
    font-size: 20px;
  }
}

/* Add these new styles */
.download-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.btn-download {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #6c757d;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  min-height: 44px;
}

.btn-download:hover {
  background-color: #5a6268;
}

/* Update existing styles */
.btn-view-invoice {
  margin-top: 1rem;
}

/* In the mobile media query, add: */
@media (max-width: 768px) {
  .download-buttons {
    margin-top: 0.5rem;
  }
  
  .btn-download {
    font-size: 14px;
  }
}

.customer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.btn-view-profile {
  padding: 6px 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.btn-view-profile:hover {
  background: #0056b3;
}

.admin-order-info {
  margin-top: 10px;
  padding: 10px;
  background-color: #e3f2fd;
  border-radius: 8px;
}

.admin-badge {
  background-color: #1976d2;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
}

.info-row.admin-order-info .label {
  color: #1976d2;
  font-weight: 600;
}

/* Add these styles for the due date functionality */
.due-date-section {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.due-date-editor {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.date-picker-input {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 120px;
}

.due-date-actions {
  display: flex;
  gap: 8px;
}

.btn-save-date,
.btn-cancel-date {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.btn-save-date {
  background-color: #28a745;
  color: white;
}

.btn-save-date:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.btn-cancel-date {
  background-color: #dc3545;
  color: white;
}

.btn-edit-date {
  padding: 2px 6px;
  margin-left: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.due-date-display {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Make sure the date picker dropdown is above other elements */
.react-datepicker-popper {
  z-index: 1000;
}
