.checkout-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.checkout-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.checkout-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 8px;
}

.step-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.3s ease;
}

.step-label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
  text-align: center;
}

.step-line {
  position: absolute;
  right: -40px;
  top: 25px;
  width: 40px;
  height: 2px;
  background: #f0f0f0;
  transition: all 0.3s ease;
}

/* Active state */
.checkout-step.active .step-icon {
  background: #3498db;
  color: white;
  animation: pulse 1.5s infinite;
}

.checkout-step.active .step-label {
  color: #3498db;
  font-weight: 600;
}

/* Completed state */
.checkout-step.completed .step-icon {
  background: #2ecc71;
  color: white;
}

.checkout-step.completed .step-line {
  background: #2ecc71;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
  }
}

@media (max-width: 768px) {
  .checkout-steps {
    flex-direction: column;
    gap: 20px;
  }

  .step-line {
    right: auto;
    top: auto;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
    width: 20px;
  }
} 