/* Container for the order tracking page */
.order-tracking-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Page title styling */
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Order tracking form styling */
  .order-tracking-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="email"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  /* Submit button styling */
  .btn-submit {
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .btn-submit:hover {
    background-color: #0056b3;
  }
  
  /* Orders list styling */
  .orders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Individual order card styling */
  .order-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .order-card-header {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .order-card-header h3 {
    margin: 0;
    color: #007bff;
    word-break: break-all;
    min-width: 200px;
  }
  
  .order-card .btn-view-invoice {
    background-color: #28a745;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
    white-space: nowrap;
    flex-shrink: 0;
  }
  
  .order-card .btn-view-invoice:hover {
    background-color: #218838;
  }
  
  .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .btn-logout {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-logout:hover {
    background-color: #c82333;
  }
  
  /* Add these new styles to your existing CSS */
  
  .otp-section {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .timer {
    color: #dc3545;
    font-size: 14px;
    font-weight: 500;
  }
  
  .btn-send-otp {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-send-otp:hover {
    background-color: #218838;
  }
  
  .btn-send-otp:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .otp-input-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .otp-timer {
    color: #6c757d;
    font-size: 14px;
    margin-top: 5px;
  }
  
  /* Update existing input styles to match new layout */
  input[type="tel"],
  input[type="text"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    flex: 1;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  /* Add these styles to OrderTracking.css */
  .order-status-timeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 15px;
    position: relative;
  }
  
  .status-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    z-index: 1;
  }
  
  .status-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    position: relative;
  }
  
  .status-label {
    font-size: 12px;
    text-align: center;
    color: #666;
    max-width: 80px;
  }
  
  .status-line {
    position: absolute;
    height: 3px;
    background: #f0f0f0;
    width: calc(100% - 40px);
    left: calc(50% + 16px);
    top: 16px;
    z-index: 0;
  }
  
  .status-step:last-child .status-line {
    display: none;
  }
  
  .status-icon.active {
    background: #4CAF50;
    color: white;
  }
  
  .status-icon.completed {
    background: #4CAF50;
    color: white;
  }
  
  .status-label.active {
    color: #4CAF50;
    font-weight: bold;
  }
  
  .status-line.completed {
    background: #4CAF50;
  }
  
  .order-details {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
  }
  
  /* Add responsive styles for smaller screens */
  @media (max-width: 480px) {
    .order-card-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .order-card .btn-view-invoice {
      margin-top: 10px;
      align-self: flex-end;
    }
  }
  
  /* Add styles for messages */
  .message {
    text-align: center;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #f8f9fa;
    color: #666;
  }
  
  .loading-message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #666;
  }
  
  /* Add these new styles */
  
  .search-section {
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .search-form {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .search-input-container {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    font-size: 20px;
    z-index: 1;
    pointer-events: none;
  }
  
  .search-input {
    width: 100%;
    padding: 12px 12px 12px 40px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    background: white;
  }
  
  .search-input:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.1);
  }
  
  .search-button, .clear-search {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .search-button {
    background: #3498db;
    color: white;
  }
  
  .clear-search {
    background: #e74c3c;
    color: white;
  }
  
  .order-card {
    background: white;
    border-radius: 12px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .order-card-header {
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
  }
  
  .header-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .order-date {
    color: #666;
    font-size: 14px;
  }
  
  .order-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .order-details.show {
    max-height: 2000px;
    transition: max-height 0.5s ease-in;
  }
  
  .order-info {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .info-section {
    margin-bottom: 15px;
  }
  
  .info-section h4 {
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .info-section p {
    font-size: 13px;
    margin: 4px 0;
  }
  
  .info-section:last-child {
    grid-column: 1 / -1;
  }
  
  .order-item {
    padding: 8px;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .order-item p {
    margin: 0;
    font-size: 13px;
  }
  
  .item-selections {
    gap: 6px;
    margin-top: 0;
  }
  
  .selection-tag {
    padding: 2px 6px;
    font-size: 12px;
  }
  
  .tracking-link {
    display: inline-block;
    margin-top: 10px;
    color: #3498db;
    text-decoration: none;
    font-weight: 600;
  }
  
  .tracking-link:hover {
    text-decoration: underline;
  }
  
  .no-orders {
    text-align: center;
    padding: 40px;
    color: #666;
    background: white;
    border-radius: 12px;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .search-form {
      flex-direction: column;
    }
    
    .search-button, .clear-search {
      width: 100%;
    }
    
    .order-card-header {
      padding: 15px;
    }
    
    .order-info {
      grid-template-columns: 1fr;
    }
    
    .info-section {
      margin-bottom: 10px;
    }
    
    .order-status-timeline {
      padding: 10px;
    }
    
    .status-icon {
      width: 28px;
      height: 28px;
    }
    
    .status-label {
      font-size: 11px;
    }
  }
  
  /* Add/Update these styles */
  
  .user-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: white;
    border-radius: 12px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .user-section p {
    font-weight: 500;
    color: #2c3e50;
  }
  
  .btn-logout {
    background: #e74c3c;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btn-logout:hover {
    background: #c0392b;
    transform: translateY(-1px);
  }
  
  .verification-form {
    background: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .verification-form h3 {
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .btn-verify {
    background: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  
  .btn-verify:hover {
    background: #2980b9;
  }
  
  .otp-input-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .otp-input-container input {
    flex: 1;
  }
  
  /* Update existing form-group styles */
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #2c3e50;
    font-weight: 500;
  }
  
  .form-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus {
    border-color: #3498db;
    outline: none;
  }
  
  /* Mobile responsiveness for timeline */
  @media (max-width: 480px) {
    .order-status-timeline {
      padding: 10px 5px;
    }

    .status-icon {
      width: 28px;
      height: 28px;
      font-size: 14px;
    }

    .status-label {
      font-size: 10px;
      max-width: 60px;
    }

    .status-line {
      width: calc(100% - 30px);
      left: calc(50% + 14px);
      top: 14px;
    }
  }
  
  .copy-icon {
    cursor: pointer;
    margin-left: 8px;
    font-size: 0.9em;
    color: #666;
    transition: color 0.2s ease;
  }
  
  .copy-icon:hover {
    color: #007bff;
  }
  
  /* Make sure the header content is properly aligned */
  .header-content h3 {
    display: flex;
    align-items: center;
    gap: 8px;
  }