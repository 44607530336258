.place-order-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.order-summary {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.cart-items {
  margin: 15px 0;
}

.cart-item {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.selection-details {
  display: flex;
  gap: 20px;
  margin: 5px 0;
  font-size: 0.9em;
  color: #666;
}

.total-amount {
  text-align: right;
  margin-top: 15px;
  font-size: 1.2em;
}

.customer-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.form-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.btn-submit,
.btn-cancel {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.btn-submit {
  background: #4CAF50;
  color: white;
}

.btn-submit:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.btn-cancel {
  background: #f44336;
  color: white;
}

.invalid-input {
  border-color: #dc3545 !important;
}

.error-message {
  color: #dc3545;
  font-size: 0.8em;
  margin-top: 4px;
  display: block;
}

.state-dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  background-color: white;
}

.state-dropdown:focus {
  outline: none;
  border-color: #4CAF50;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.step-content {
  min-height: 400px;
  margin: 10px 0;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 15px 0;
  border-top: 1px solid #eee;
  gap: 10px;
}

.step-navigation.with-back {
  justify-content: space-between;
}

.btn-back {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  background-color: #f5f5f5;
  color: #333;
}

.btn-next,
.btn-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  background-color: #4CAF50;
  color: white;
}

.btn-next:disabled,
.btn-submit:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.customer-summary {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 15px;
}

.details-grid > div {
  padding: 10px;
  background: #f9f9f9;
  border-radius: 4px;
}

.item-details-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.item-details-table th,
.item-details-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.item-details-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.order-totals {
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 4px;
}

.total-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.grand-total {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid #ddd;
  font-weight: bold;
  font-size: 1.1em;
}

.admin-details {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-details .form-group {
  margin: 15px 0;
}

.admin-details label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.admin-details input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

/* Optional GST input styling */
input[name="gstNumber"] {
  background-color: #f8f9fa;
  border: 1px dashed #ddd;
}

input[name="gstNumber"]::placeholder {
  color: #999;
}

.phone-search {
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-input input {
  flex: 1;
}

.btn-search {
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.btn-search:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-search:hover:not(:disabled) {
  background-color: #357abd;
}

.customer-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Remove the redundant headers */
.customer-details-step h2 {
  display: none;
} 