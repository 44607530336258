.admin-layout {
  display: flex;
  min-height: 100vh;
  width: 100vw; /* Change to viewport width */
  position: relative;
  overflow: hidden; /* Add this */
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.sidebar-expanded .main-content {
  margin-left: 260px;
  width: calc(100% - 260px);
}

.sidebar-collapsed .main-content {
  margin-left: 70px;
  width: calc(100% - 70px);
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow: auto; /* Change to auto */
  position: absolute; /* Add this */
  top: 0; /* Change from 64px to 0 */
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 768px) {
  .sidebar-expanded .main-content,
  .sidebar-collapsed .main-content {
    margin-left: 0;
    width: 100%;
  }
  
  .main-content {
    padding-top: 56px;
  }
  
  .content-wrapper {
    padding: 12px;
    height: calc(100vh - 56px); /* Adjust for mobile header height */
  }
} 