/* Manage Products Container */
.manage-products {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-product-button {
  margin-bottom: 20px;
  text-align: right;
}

.btn-add {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
}

.btn-add:hover {
  background-color: #218838;
}

/* Products Table */
.products-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.products-table th,
.products-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.products-table th {
  background-color: #007bff;
  color: white;
}

.product-image {
  width: 80px;
  height: 80px;
  object-fit: contain;
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 4px;
}

/* Button Styling */
.btn-edit,
.btn-manage-skus {
  padding: 5px 10px;
  margin-right: 5px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.btn-edit {
  background-color: #ffc107;
}

.btn-edit:hover {
  background-color: #e0a800;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .manage-products {
    padding: 15px;
  }

  /* Center the Add Product button on mobile */
  .add-product-button {
    text-align: center;
  }

  /* Compact Table on Mobile */
  .products-table th,
  .products-table td {
    padding: 8px;
    font-size: 14px;
  }

  /* Stack Table for Mobile */
  .products-table,
  .products-table tr,
  .products-table th,
  .products-table td {
    display: block;
    width: 100%;
  }

  .products-table tr {
    min-width: 300px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  .products-table th {
    display: none; /* Hide headers on mobile */
  }

  .products-table td {
    padding: 8px 8px 8px 45%;
    text-align: right;
    min-height: 44px;
    position: relative;
    border-bottom: 1px solid #ddd;
  }

  .products-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    padding-right: 10px;
    font-weight: bold;
    text-align: left;
  }

  /* Adjust Button Sizes on Mobile */
  .btn-edit {
    font-size: 14px;
    padding: 8px;
  }

  .product-image {
    max-width: 80px;
    height: auto;
    margin-left: auto;
  }

  /* Make sure the clickable row is more obvious */
  .clickable-row {
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .clickable-row:active {
    background-color: #f0f0f0;
  }
}

/* Add these styles to your existing ManageProducts.css */
.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable-row:hover {
  background-color: #f5f5f5;
}

/* Ensure the image column doesn't take too much space */
.products-table td img.product-image {
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
}

.products-table td[data-label="Image"] {
  background-color: #f8f9fa;
  width: 90px;
  height: 90px;
}

/* Product specific styles */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.product-thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #eee;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s;
}

.clickable-row:hover {
  background-color: #f8f9fa;
}

.no-data {
  text-align: center;
  color: #666;
  padding: 40px;
  font-style: italic;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #666;
}

@media (max-width: 1024px) {
  .admin-table {
    table-layout: auto;
  }

  .admin-table th,
  .admin-table td {
    min-width: auto;
  }
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    gap: 16px;
  }

  .admin-action-btn {
    width: 100%;
  }

  .admin-table {
    display: block;
    overflow-x: auto;
  }

  .admin-table th,
  .admin-table td {
    white-space: normal;
  }

  .product-thumbnail {
    width: 40px;
    height: 40px;
  }
}
