/* Manage Orders Container */
.manage-orders {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Allow horizontal scrolling */
}

.manage-orders h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Orders Table */
.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; /* Prevents shrinking of columns */
}

.orders-table th,
.orders-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  min-width: 300px; /* Minimum width to prevent excessive compression */
  word-wrap: break-word;
}

.orders-table th {
  background-color: #4a90e2;
  color: white;
}

.orders-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Pending Order Highlight */
.pending-order {
  background-color: #fff3cd; /* Light yellow for pending orders */
}

/* Button Styling */
.btn-view-details,
.btn-save-status {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.btn-view-details:hover,
.btn-save-status:hover {
  background-color: #0056b3;
}

.status-dropdown {
  padding: 5px;
  border-radius: 4px;
}
.filter-refresh-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  align-items: center;
}

.status-filter-dropdown, .btn-refresh {
  margin-left: 5px;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .manage-orders {
    padding: 15px;
  }

  .filter-refresh-container {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }

  .actions-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .btn-place-order, 
  .btn-refresh {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  /* Stack Table for Mobile */
  .orders-table, 
  .orders-table tbody,
  .orders-table tr, 
  .orders-table td {
    display: block;
    width: 100%;
  }

  .orders-table tr {
    margin-bottom: 15px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }

  .orders-table th {
    display: none;
  }

  .orders-table td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    border-bottom: 1px solid #eee;
    min-height: 44px;
    text-align: right;
  }

  .orders-table td::before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 15px;
    text-align: left;
    flex: 1;
  }

  /* Specific styling for different types of data */
  .orders-table td[data-label="Order ID"] {
    background-color: #f8f9fa;
    font-weight: 500;
  }

  .orders-table td[data-label="Total Amount"] {
    color: #28a745;
    font-weight: 500;
  }

  /* Status badge container */
  .status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    margin-left: auto;
  }

  .status-badge {
    margin: 0;
    white-space: nowrap;
  }

  .admin-order-badge {
    font-size: 0.75em;
    padding: 2px 6px;
    white-space: normal;
    text-align: right;
    max-width: 200px;
  }

  /* Action buttons */
  td[data-label="Actions"] {
    border-bottom: none;
  }

  .btn-view-details {
    width: auto;
    min-width: 120px;
    margin-left: auto;
    padding: 8px 16px;
  }

  /* Date group header */
  .date-header {
    padding: 10px 15px;
    margin: 15px 0 10px;
    background-color: #e9ecef;
    border-radius: 4px;
    font-size: 0.9em;
    color: #495057;
  }

  /* Due date styling */
  td[data-label="Due Date"] span {
    color: #dc3545;
    font-weight: 500;
  }

  /* Assigned To styling */
  td[data-label="Assigned To"] {
    color: #6c757d;
  }
}

.status-badge {
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
  text-transform: capitalize;
}

.status-badge.pending {
  background-color: #fff3cd;
  color: #856404;
}

.status-badge.packed {
  background-color: #cce5ff;
  color: #004085;
}

.status-badge.shipped {
  background-color: #d4edda;
  color: #155724;
}

.status-badge.delivered {
  background-color: #d1e7dd;
  color: #0f5132;
}

.status-badge.cancelled {
  background-color: #f8d7da;
  color: #842029;
}

.actions-container {
  display: flex;
  gap: 10px;
}

.btn-place-order {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.btn-place-order:hover {
  background-color: #45a049;
}

.status-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.admin-order-badge {
  background-color: #e3f2fd;
  color: #1976d2;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: 500;
}

/* Add these styles for the due date functionality */
.due-date-editor {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.date-picker-input {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 120px;
}

.due-date-actions {
  display: flex;
  gap: 4px;
}

.btn-save-date,
.btn-cancel-date {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.btn-save-date {
  background-color: #28a745;
  color: white;
}

.btn-save-date:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.btn-cancel-date {
  background-color: #dc3545;
  color: white;
}

.btn-edit-date {
  padding: 2px 6px;
  margin-left: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.due-date-display {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Make sure the date picker dropdown is above other elements */
.react-datepicker-popper {
  z-index: 1000;
}

/* Responsive styles */
@media (max-width: 768px) {
  .due-date-editor {
    width: 100%;
  }

  .date-picker-input {
    width: 100%;
  }

  .due-date-actions {
    justify-content: space-between;
  }
}
