.customer-profile-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.profile-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.back-button {
  padding: 8px 16px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.back-button:hover {
  background: #e0e0e0;
}

.profile-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.customer-details, 
.order-history {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.info-display p {
  margin: 10px 0;
  padding: 8px;
  background: #f8f9fa;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.btn-save,
.btn-cancel,
.btn-edit,
.btn-view-order {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.btn-save {
  background: #28a745;
  color: white;
}

.btn-save:hover {
  background: #218838;
}

.btn-cancel {
  background: #6c757d;
  color: white;
}

.btn-cancel:hover {
  background: #5a6268;
}

.btn-edit {
  background: #007bff;
  color: white;
}

.btn-edit:hover {
  background: #0056b3;
}

.btn-view-order {
  background: #17a2b8;
  color: white;
}

.btn-view-order:hover {
  background: #138496;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.orders-table th,
.orders-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.orders-table th {
  background: #e9ecef;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #dee2e6;
}

.orders-table tr:hover {
  background: #f8f9fa;
}

.orders-table thead tr {
  border-bottom: 2px solid #dee2e6;
}

.orders-table thead tr th:first-child {
  border-top-left-radius: 4px;
}

.orders-table thead tr th:last-child {
  border-top-right-radius: 4px;
}

.no-orders {
  color: #6c757d;
  text-align: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 4px;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
}

@media (max-width: 768px) {
  .profile-content {
    grid-template-columns: 1fr;
  }
  
  .orders-table {
    display: block;
    overflow-x: auto;
  }

  .orders-table th,
  .orders-table td {
    min-width: 120px;
  }
  
  .button-group {
    flex-direction: column;
  }
  
  .btn-save,
  .btn-cancel,
  .btn-edit,
  .btn-view-order {
    width: 100%;
  }
} 