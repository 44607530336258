/* Success Page Styling */
.success-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #f8f9fa;
}

.success-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 30px;
  max-width: 600px;
  width: 100%;
}

.success-header {
  text-align: center;
  margin-bottom: 30px;
}

.success-icon {
  font-size: 64px;
  color: #2ecc71;
  margin-bottom: 20px;
  animation: scaleIn 0.5s ease-out;
}

.success-header h2 {
  color: #2c3e50;
  margin-bottom: 10px;
  font-size: 28px;
}

.order-id {
  color: #7f8c8d;
  font-size: 16px;
}

.order-summary {
  margin: 30px 0;
}

.summary-section {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}

.summary-section h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #2c3e50;
  margin-bottom: 15px;
  font-size: 18px;
}

.summary-details {
  display: grid;
  gap: 10px;
}

.summary-details p {
  color: #34495e;
}

.communication-info {
  background: #e8f4fd;
  padding: 15px;
  border-radius: 12px;
  margin: 20px 0;
}

.communication-info p {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2980b9;
  margin: 8px 0;
}

.next-steps {
  margin: 25px 0;
}

.next-steps h3 {
  color: #2c3e50;
  margin-bottom: 15px;
}

.next-steps ul {
  list-style-type: none;
  padding: 0;
}

.next-steps li {
  padding: 8px 0;
  color: #34495e;
  position: relative;
  padding-left: 20px;
}

.next-steps li:before {
  content: "•";
  color: #3498db;
  position: absolute;
  left: 0;
}

.success-actions {
  display: flex;
  gap: 15px;
  margin-top: 30px;
}

.track-order-button,
.continue-shopping-button {
  flex: 1;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.track-order-button {
  background: #3498db;
  color: white;
}

.continue-shopping-button {
  background: #ecf0f1;
  color: #2c3e50;
}

.track-order-button:hover {
  background: #2980b9;
  transform: translateY(-2px);
}

.continue-shopping-button:hover {
  background: #bdc3c7;
  transform: translateY(-2px);
}

@keyframes scaleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .success-card {
    padding: 20px;
  }

  .success-actions {
    flex-direction: column;
  }

  .success-header h2 {
    font-size: 24px;
  }

  .success-icon {
    font-size: 48px;
  }
}

/* Add these new styles to your existing Success.css */

.success-icon.warning {
  color: #f39c12;
}

.no-order-message {
  color: #7f8c8d;
  font-size: 16px;
  line-height: 1.5;
  margin: 20px 0;
  text-align: center;
}

.empty-success-actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.continue-shopping-button.primary {
  background: #3498db;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 30px;
  font-size: 18px;
}

.continue-shopping-button.primary:hover {
  background: #2980b9;
  transform: translateY(-2px);
}

/* Add to your existing animations */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

.success-icon.warning {
  animation: shake 0.5s ease-in-out;
}