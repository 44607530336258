.product-details-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.product-details-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}

.info-item {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.product-description {
  margin: 20px 0;
}

.product-image {
  margin-top: 20px;
  text-align: center;
}

.product-image img {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  border-radius: 4px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn-edit, .btn-delete, .btn-logs {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  text-decoration: none;
  text-align: center;
}

.btn-edit {
  background-color: #4CAF50;
  color: white;
}

.btn-logs {
  background-color: #9c27b0;
  color: white;
}

.btn-logs:hover {
  background-color: #7b1fa2;
}

.btn-delete {
  background-color: #f44336;
  color: white;
}

@media (max-width: 768px) {
  .product-details-grid {
    grid-template-columns: 1fr;
  }
  
  .product-info-grid {
    grid-template-columns: 1fr;
  }
}

/* SKU Section */
.sku-section {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.sku-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.sku-header h3 {
  margin: 0;
}

/* Button Styles */
.btn-add {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-edit, 
.btn-delete,
.btn-logs {
  padding: 6px 12px;
  font-size: 0.9em;
  min-width: 90px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  box-sizing: border-box;
}

.btn-edit {
  background-color: #2196F3;
  color: white;
}

.btn-delete {
  background-color: #f44336;
  color: white;
}

.btn-logs {
  background-color: #9c27b0;
  color: white;
}

/* Image Styles */
.sku-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

/* Pricing List Styles */
.pricing-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pricing-list li {
  margin-bottom: 4px;
  font-size: 0.9em;
}

/* Action Column Styles */
.sku-table td:last-child {
  white-space: nowrap;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.btn-edit, 
.btn-delete,
.btn-logs {
  padding: 6px 12px;
  font-size: 0.9em;
  min-width: 90px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  box-sizing: border-box;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .sku-table td:last-child {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    gap: 4px;
  }
  
  .btn-edit, 
  .btn-delete,
  .btn-logs {
    margin-right: 0;
    width: 100%;
  }
}

/* Table Styles */
.sku-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.sku-table th,
.sku-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.sku-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.sku-table tbody tr:hover {
  background-color: #f9f9f9;
}

/* Modal Styles */
.modal-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 9999 !important;
  visibility: visible !important;
  opacity: 1 !important;
  padding: 20px;
  box-sizing: border-box;
}

.modal {
  position: relative !important;
  display: block !important;
  width: 600px !important;
  max-width: 90% !important;
  min-height: 200px !important;
  background-color: white !important;
  padding: 0 !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
  z-index: 10000 !important;
  margin: auto !important;
  height: auto !important;
  overflow: visible !important;
  display: flex !important;
  flex-direction: column !important;
  max-height: calc(100vh - 40px) !important;
}

.modal-header {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #eee;
  flex-shrink: 0;
}

.modal-content {
  padding: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  max-height: calc(100vh - 200px);
}

.modal-actions {
  padding: 10px 20px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-shrink: 0;
}

/* Form elements in modal */
.modal input[type="text"],
.modal input[type="number"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal input[type="file"] {
  margin-bottom: 10px;
}

.modal button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.modal button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal .btn-save {
  background-color: #4CAF50;
  color: white;
}

.modal .btn-cancel {
  background-color: #6c757d;
  color: white;
}

/* Pricing section in modal */
.pricing-entry {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.pricing-inputs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 8px;
}

/* Responsive styles for modal */
@media (max-width: 768px) {
  .modal {
    width: 95% !important;
    margin: 10px !important;
  }

  .pricing-inputs {
    grid-template-columns: 1fr;
  }

  .modal-content {
    padding: 15px;
    max-height: calc(100vh - 150px);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .sku-table {
    display: block;
    overflow-x: auto;
  }

  .pricing-inputs {
    grid-template-columns: 1fr;
  }
}

.product-actions {
  margin: 20px 0;
  display: flex;
  gap: 10px;
}

.btn-logs {
  padding: 10px 20px;
  background-color: #9c27b0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
}

.btn-logs:hover {
  background-color: #7b1fa2;
}

.product-images {
  margin-top: 20px;
}

.main-image {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 400px;
  padding: 8px;
}

.image-thumbnails {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.image-thumbnails::-webkit-scrollbar {
  height: 6px;
}

.image-thumbnails::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.image-thumbnails::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.thumbnail {
  flex: 0 0 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
  background-color: #f8f9fa;
}

.thumbnail:hover {
  border-color: #4CAF50;
}

.thumbnail.active {
  border-color: #4CAF50;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-image {
    max-height: 300px;
  }

  .thumbnail {
    flex: 0 0 60px;
    height: 60px;
  }
} 