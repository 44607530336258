.invoice-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background: white;
}

.invoice-actions {
  margin-bottom: 20px;
}

.btn-print {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-print:hover {
  background-color: #0056b3;
}

.invoice-content {
  border: 1px solid #ddd;
  padding: 20px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 30px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background-color: #f8f9fa;
}

.invoice-summary {
  margin-top: 20px;
  text-align: right;
}

/* Print styles */
@media print {
  .no-print {
    display: none;
  }
  
  .invoice-container {
    margin: 0;
    padding: 0;
  }

  .invoice-content {
    border: none;
  }
}

/* Add to OrderDetails.css */
.btn-view-invoice {
  background-color: #6c757d;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
.price-note {
  font-size: 0.8em;
  color: #666;
  font-style: italic;
  margin-top: 4px;
}

.gst-breakdown {
  margin: 20px 0;
}

.gst-breakdown h4 {
  margin-bottom: 10px;
}

.gst-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.gst-table th,
.gst-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: right;
}

.gst-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.totals {
  margin-left: auto;
  width: 300px;
}

.total-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.grand-total {
  font-weight: bold;
  font-size: 1.1em;
  border-top: 2px solid #333;
  border-bottom: none;
  margin-top: 8px;
  padding-top: 12px;
}

.invoice-items {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  table-layout: fixed;
}

.invoice-items th,
.invoice-items td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  vertical-align: top;
}

.invoice-items th {
  background-color: #f8f9fa;
  font-weight: 600;
}

/* Fixed column widths */
.invoice-items th:nth-child(1),
.invoice-items td:nth-child(1) {
  width: 35%;  /* Description column */
}

.invoice-items th:nth-child(2),
.invoice-items td:nth-child(2) {
  width: 10%;  /* Quantity column */
  text-align: center;
}

.invoice-items th:nth-child(3),
.invoice-items td:nth-child(3) {
  width: 15%;  /* Rate column */
  text-align: right;
}

.invoice-items th:nth-child(4),
.invoice-items td:nth-child(4) {
  width: 10%;  /* GST % column */
  text-align: center;
}

.invoice-items th:nth-child(5),
.invoice-items td:nth-child(5) {
  width: 15%;  /* GST Amount column */
  text-align: right;
}

.invoice-items th:nth-child(6),
.invoice-items td:nth-child(6) {
  width: 15%;  /* Amount column */
  text-align: right;
}

/* Price note styling within the description cell */
.price-note {
  font-size: 0.8em;
  color: #666;
  font-style: italic;
  margin-top: 4px;
  white-space: nowrap;
}

/* Ensure long content wraps properly */
.invoice-items td {
  word-wrap: break-word;
  overflow-wrap: break-word;
} 