.order-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.order-loader-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 500px;
}

.order-loader-steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.order-loader-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  color: #ccc;
}

.step-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
}

.order-loader-step.active .step-icon {
  background-color: #007bff;
  color: white;
}

.order-loader-step.completed .step-icon {
  background-color: #28a745;
  color: white;
}

.step-label {
  font-size: 0.9rem;
  text-align: center;
  color: #666;
}

.order-loader-step.active .step-label,
.order-loader-step.completed .step-label {
  color: #333;
}

.step-connector {
  position: absolute;
  top: 20px;
  left: 50%;
  right: -50%;
  height: 2px;
  background-color: #f0f0f0;
  z-index: 1;
}

.order-loader-step.completed .step-connector {
  background-color: #28a745;
}

.check-mark {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #28a745;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.loading-spinner {
  margin: 20px auto 0;
  width: 40px;
  height: 40px;
  border: 3px solid #f0f0f0;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
  .step-label {
    font-size: 0.8rem;
  }

  .step-icon {
    width: 32px;
    height: 32px;
    font-size: 0.9rem;
  }

  .check-mark {
    width: 16px;
    height: 16px;
    font-size: 10px;
  }
} 