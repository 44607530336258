.skeleton {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 4px;
  background-size: 200% 100%;
  animation: shine 1.5s linear infinite;
}

.skeleton-text {
  height: 16px;
  margin-bottom: 8px;
}

.skeleton-circular {
  border-radius: 50%;
}

.skeleton-rectangular {
  border-radius: 4px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
} 