/* Login Container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  /* Login Card */
  .login-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  /* Form Group */
  .form-group {
    margin-bottom: 15px;
  }
  
  /* Form Input */
  .form-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  /* Login Button */
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  /* Error Message */
  .error-message {
    color: red;
    margin-bottom: 15px;
  }
  
  /* Disabled Button Style */
  .login-button:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
  