.skeleton-user-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.skeleton-user-header {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
}

.skeleton-user-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.skeleton-user-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.skeleton-detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeleton-user-actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid #eee;
}

@media (min-width: 768px) {
  .skeleton-user-card {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 24px;
    align-items: center;
    padding: 12px 16px;
    margin-bottom: 8px;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #eee;
  }

  .skeleton-user-header,
  .skeleton-user-details {
    border: none;
    padding: 0;
    margin: 0;
  }

  .skeleton-user-actions {
    border: none;
    padding: 0;
  }
} 