.skeleton-order-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.skeleton-order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
}

.skeleton-order-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.skeleton-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeleton-order-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid #eee;
}

@media (min-width: 768px) {
  .skeleton-order-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 16px;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #eee;
  }

  .skeleton-order-header,
  .skeleton-order-details,
  .skeleton-order-footer {
    border: none;
    padding: 0;
    margin: 0;
  }
} 