/* Base styles for admin component containers */
.admin-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 64px; /* Add this to account for header height */
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  max-width: none;
  overflow: auto;
}

/* Remove any max-width from old styles */
.manage-products,
.manage-users,
.manage-orders,
.admin-dashboard {
  max-width: none;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Common header styles */
.admin-header-title {
  font-size: 24px;
  font-weight: 500;
  color: #1a73e8;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
  width: 100%;
}

/* Common table container styles */
.table-container {
  flex: 1;
  width: 100%;
  margin-top: 20px;
  overflow: auto;
  background: white;
  border-radius: 8px;
  min-width: 100%;
  align-self: stretch;
  position: relative;
}

/* Common table styles */
.admin-table {
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: auto;
}

.admin-table th {
  background-color: #f8f9fa;
  color: #5f6368;
  font-weight: 500;
  text-align: left;
  padding: 12px 16px;
  border-bottom: 2px solid #eee;
  white-space: nowrap;
}

.admin-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  color: #3c4043;
  word-wrap: break-word;
}

.admin-table tr:hover {
  background-color: #f8f9fa;
}

/* Common action button styles */
.admin-action-btn {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.btn-primary {
  background-color: #1a73e8;
  color: white;
}

.btn-primary:hover {
  background-color: #1557b0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .admin-container {
    padding: 16px;
    margin-top: 56px; /* Adjust for mobile header height */
  }

  .admin-header-title {
    font-size: 20px;
    margin-bottom: 16px;
    padding-bottom: 12px;
  }

  .admin-table th,
  .admin-table td {
    min-width: unset; /* Remove min-width constraints on mobile */
    width: 100%; /* Full width on mobile */
  }
}

/* Remove or modify these column width constraints */
.admin-table th,
.admin-table td {
  min-width: 120px; /* Keep this for minimum width */
}

/* Remove or update these specific column widths */
.admin-table th:first-child,
.admin-table td:first-child {
  min-width: 200px; /* Use min-width instead */
}

.admin-table th:nth-child(2),
.admin-table td:nth-child(2) {
  min-width: 120px;
}

.admin-table th:nth-child(3),
.admin-table td:nth-child(3) {
  min-width: 100px;
}

.admin-table th:last-child,
.admin-table td:last-child {
  min-width: 100px;
} 