.admin-dashboard {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.dashboard-header {
  margin-bottom: 24px;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.card-admin {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card-admin h3 {
  margin: 0 0 10px 0;
  color: #666;
  font-size: 1em;
}

.card-value {
  font-size: 2em;
  font-weight: 600;
  margin: 0;
  color: #2196F3;
}

.inventory-section {
  margin-top: 30px;
}

.sku-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.sku-table th,
.sku-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.sku-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.sku-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.stock-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
}

.stock-badge.warning {
  background-color: #fff3e0;
  color: #f57c00;
}

.stock-badge.critical {
  background-color: #ffebee;
  color: #d32f2f;
}

.no-data {
  text-align: center;
  color: #666;
  padding: 20px;
}

.dashboard-charts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.chart-section {
  padding: 20px;
  min-height: 380px;
  display: flex;
  flex-direction: column;
}

.pie-chart-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 280px;
  max-height: 330px;
  width: 100%;
  margin: 10px 0;
}

.time-filter-section {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  font-size: 0.9em;
  transition: all 0.2s ease;
}

.filter-btn:hover {
  background: #f5f5f5;
}

.filter-btn.active {
  background: #2196F3;
  color: white;
  border-color: #2196F3;
}

@media (max-width: 768px) {
  .sku-table {
    display: block;
    overflow-x: auto;
  }

  .dashboard-charts {
    grid-template-columns: 1fr;
  }

  .pie-chart-container {
    min-height: 230px;
  }

  .time-filter-section {
    flex-direction: column;
  }

  .filter-btn {
    width: 100%;
    text-align: center;
  }
}
