.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: #fff;
  box-shadow: 2px 0 8px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar.expanded {
  width: 260px;
}

.sidebar.collapsed {
  width: 70px;
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.logo {
  font-size: 24px;
  color: #1a73e8;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar-nav {
  padding: 16px 0;
  flex: 1;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  color: #5f6368;
  text-decoration: none;
  transition: all 0.2s ease;
  margin: 4px 8px;
  border-radius: 8px;
}

.nav-item:hover {
  background: #f1f3f4;
  color: #1a73e8;
}

.nav-item.active {
  background: #e8f0fe;
  color: #1a73e8;
}

.nav-item .icon {
  min-width: 24px;
  margin-right: 16px;
}

.nav-item .label {
  flex: 1;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.nav-item .arrow {
  opacity: 0;
  transition: all 0.2s ease;
}

.nav-item:hover .arrow {
  opacity: 1;
  transform: translateX(4px);
}

.collapsed .nav-item {
  padding: 12px;
  justify-content: center;
}

.collapsed .nav-item .label,
.collapsed .nav-item .arrow {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.expanded {
    transform: translateX(0);
  }
} 