.product-form-container {
  /* Remove these as they're now handled by admin-container */
  /* max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
}

.form-container {
  background: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.form-section {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.form-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #5f6368;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #1a73e8;
  outline: none;
}

.form-group small {
  display: block;
  margin-top: 4px;
  color: #666;
  font-size: 12px;
}

.button-container {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.btn-submit {
  background-color: #1a73e8;
  color: white;
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-submit:hover {
  background-color: #1557b0;
}

.btn-submit:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .form-container {
    padding: 16px;
  }

  .form-section {
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .button-container {
    margin-top: 16px;
  }
}

.image-preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.image-preview-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 1;
}

.image-preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #ff4444;
  padding: 0;
  line-height: 1;
}

.remove-image:hover {
  background-color: #ff4444;
  color: white;
}

.upload-progress {
  margin-top: 10px;
  padding: 8px;
  background-color: #e3f2fd;
  border-radius: 4px;
  text-align: center;
  color: #1976d2;
}
