.admin-header {
  background: #fff;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  right: 0;
  left: 70px;
  z-index: 999;
  transition: left 0.3s ease;
  width: calc(100% - 70px);
  box-sizing: border-box;
}

.sidebar-expanded .admin-header {
  left: 260px;
  width: calc(100% - 260px);
}

.sidebar-collapsed .admin-header {
  left: 70px;
  width: calc(100% - 70px);
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.menu-toggle {
  background: none;
  border: none;
  color: #5f6368;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
}

.menu-toggle:hover {
  background: #f1f3f4;
}

.search-bar {
  display: flex;
  align-items: center;
  background: #f1f3f4;
  border-radius: 24px;
  padding: 8px 16px;
  gap: 8px;
}

.search-bar input {
  border: none;
  background: none;
  outline: none;
  font-size: 14px;
  width: 300px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-icon-btn {
  background: none;
  border: none;
  color: #5f6368;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background: #ea4335;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
}

.user-menu {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logout-btn {
  background: none;
  border: none;
  color: #5f6368;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
}

.logout-btn:hover {
  background: #f1f3f4;
}

@media (max-width: 768px) {
  .admin-header {
    left: 0;
    width: 100%;
    padding: 12px 16px;
  }
  
  .search-bar {
    display: none;
  }
  
  .header-right {
    gap: 8px;
  }

  .user-menu {
    gap: 4px;
  }

  .logout-btn {
    padding: 8px;
    font-size: 14px;
  }
} 