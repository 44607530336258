.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.step-container {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.step-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease;
  border: 2px solid #e0e0e0;
}

.step.active .step-circle {
  background-color: #e8f5e9;
  border-color: #4CAF50;
}

.step.current .step-circle {
  background-color: #4CAF50;
  border-color: #4CAF50;
  transform: scale(1.1);
}

.step-icon {
  font-size: 24px;
  color: #757575;
  transition: all 0.3s ease;
}

.step.active .step-icon {
  color: #4CAF50;
}

.step.current .step-icon {
  color: white;
}

.step-number {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: #757575;
}

.step.active .step-number {
  border-color: #4CAF50;
  color: #4CAF50;
}

.step.current .step-number {
  background-color: #4CAF50;
  border-color: #fff;
  color: #fff;
}

.step-label {
  margin-top: 8px;
  font-size: 14px;
  color: #757575;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
}

.step.active .step-label {
  color: #4CAF50;
}

.step.current .step-label {
  font-weight: 600;
}

.connector {
  flex: 1;
  padding: 0 15px;
  position: relative;
}

.connector-line {
  height: 2px;
  background-color: #e0e0e0;
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  transition: all 0.3s ease;
}

.connector.active .connector-line {
  background-color: #4CAF50;
}

@media (max-width: 600px) {
  .step-label {
    font-size: 12px;
  }

  .step-circle {
    width: 40px;
    height: 40px;
  }

  .step-icon {
    font-size: 20px;
  }

  .step-number {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
} 