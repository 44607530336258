.inventory-logs {
  /* Remove these as they're now handled by admin-container */
  /* padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}

.logs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 15px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sku-filter {
  margin-left: auto;
}

.sku-select {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  min-width: 200px;
  font-size: 14px;
}

.filter-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  font-size: 0.9em;
  transition: all 0.2s ease;
}

.filter-btn:hover {
  background: #f5f5f5;
}

.filter-btn.active {
  background: #2196F3;
  color: white;
  border-color: #2196F3;
}

.logs-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.logs-table th,
.logs-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.logs-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.positive-change {
  color: #34a853;
  font-weight: 500;
}

.negative-change {
  color: #ea4335;
  font-weight: 500;
}

.no-logs {
  text-align: center;
  padding: 20px;
  color: #666;
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    gap: 16px;
  }

  .header-left {
    flex-direction: column;
    align-items: flex-start;
  }

  .sku-filter {
    width: 100%;
    margin-left: 0;
  }

  .sku-select {
    width: 100%;
  }

  .btn-back {
    width: 100%;
    justify-content: center;
  }
}

.btn-back {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  font-size: 14px;
  color: #5f6368;
  transition: all 0.2s ease;
}

.btn-back:hover {
  background: #f1f3f4;
  color: #1a73e8;
} 