/* Manage Inventory Container */
.manage-inventory {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Inventory Table */
.inventory-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.inventory-table th,
.inventory-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.inventory-table th {
  background-color: #007bff;
  color: white;
  font-weight: 500;
}

.inventory-table tr:hover {
  background-color: #f8f9fa;
}

/* Button Styles */
.btn-edit {
  padding: 8px 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-edit:hover {
  background-color: #218838;
}

/* Base styles */
.inventory-thumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.product-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.stock-badge {
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #e8f5e9;
  color: #2e7d32;
  font-weight: 500;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.stock-badge.low {
  background-color: #ffebee;
  color: #c62828;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.modal-content {
  padding: 20px;
}

.modal-actions {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.stock-update-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sku-info {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
}

.sku-info p {
  margin: 5px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: 500;
}

.form-group input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.btn-save {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-cancel {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

/* Loading State */
.loading {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .inventory-table {
    display: block;
    overflow-x: auto;
  }

  .inventory-table th,
  .inventory-table td {
    min-width: 120px;
  }

  /* Stack Table on Mobile */
  .inventory-table,
  .inventory-table tbody,
  .inventory-table tr,
  .inventory-table td {
    display: block;
    width: 100%;
  }

  .inventory-table tr {
    margin-bottom: 15px;
    border: 1px solid #ddd;
  }

  .inventory-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .inventory-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 45%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }

  .modal {
    width: 95%;
    margin: 10px;
  }
}

/* SKU Image styles */
.sku-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
}

/* Modal SKU image */
.sku-image {
  text-align: center;
  margin-bottom: 15px;
}

.modal-sku-image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: white;
}

/* Update responsive styles */
@media (max-width: 768px) {
  .sku-thumbnail {
    width: 40px;
    height: 40px;
  }

  .modal-sku-image {
    width: 100px;
    height: 100px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .admin-table {
    display: block;
    width: 100%;
  }

  .admin-table thead {
    display: none;
  }

  .admin-table tbody {
    display: block;
    width: 100%;
  }

  .admin-table tr {
    display: block;
    margin-bottom: 16px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    overflow: hidden;
  }

  .admin-table td {
    display: flex;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .admin-table td:last-child {
    border-bottom: none;
  }

  .admin-table td > *:not(:first-child) {
    text-align: right;
    flex-shrink: 0;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td[data-label="SKU"],
  td[data-label="Color"],
  td[data-label="Size"] {
    font-size: 14px;
  }

  td[data-label="SKU"]::before,
  td[data-label="Color"]::before,
  td[data-label="Size"]::before,
  td[data-label="Stock"]::before {
    content: attr(data-label);
    font-weight: 500;
    color: #666;
    flex-shrink: 0;
    width: 40%;
    text-align: left;
  }

  td[data-label="SKU"] span,
  td[data-label="Color"] span,
  td[data-label="Size"] span {
    text-align: right;
    margin-left: auto;
  }

  td[data-label="Product"] {
    background: #f8f9fa;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 16px;
  }

  .product-info {
    width: 100%;
    gap: 16px;
  }

  td[data-label="Stock"] .stock-badge {
    margin-left: auto;
  }

  .inventory-thumbnail {
    width: 48px;
    height: 48px;
  }

  td[data-label="Actions"] {
    padding: 16px;
  }

  td[data-label="Actions"]::before {
    display: none;
  }

  .admin-action-btn {
    width: 100%;
    padding: 12px;
    margin: 0;
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .admin-table th,
  .admin-table td {
    padding: 12px 8px;
  }

  .inventory-thumbnail {
    width: 36px;
    height: 36px;
  }

  .admin-action-btn {
    padding: 6px 12px;
    font-size: 14px;
  }
}