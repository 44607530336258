.manage-users {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

.manage-users h2 {
    text-align: center;
    margin-bottom: 20px;
}

.users-table {
    min-width: 100%;
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.users-table th,
.users-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.users-table th {
    background-color: #4a90e2;
    color: white;
}

.users-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.btn-edit,
.btn-save {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
}

.btn-edit:hover,
.btn-save:hover {
    background-color: #0056b3;
}

input[type="text"] {
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .manage-users {
        padding: 15px;
    }

    .users-table,
    .users-table tbody,
    .users-table tr,
    .users-table td {
        display: block;
        width: 100%;
    }

    .users-table tr {
        margin-bottom: 15px;
        background: white;
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
    }

    .users-table th {
        display: none;
    }

    .users-table td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 15px;
        border-bottom: 1px solid #eee;
        min-height: 44px;
        text-align: right;
    }

    .users-table td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-right: 15px;
        text-align: left;
        flex: 1;
    }

    .users-table td[data-label="Name"] {
        background-color: #f8f9fa;
        font-weight: 500;
    }

    .users-table td[data-label="Email"] {
        color: #1976d2;
    }

    .users-table td[data-label="Mobile"] {
        color: #6c757d;
    }

    td[data-label="Actions"] {
        border-bottom: none;
    }

    .btn-view {
        width: auto;
        min-width: 120px;
        margin-left: auto;
        padding: 8px 16px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .btn-view:hover {
        background-color: #0056b3;
    }
}

.permissions-select {
    min-width: 200px;
    min-height: 100px;
    padding: 5px;
}

.permissions-select option {
    padding: 5px;
}

select {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.permissions-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    padding: 8px;
}

.permission-checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    user-select: none;
}

.permission-checkbox-label input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.admin-note {
    color: #666;
    font-style: italic;
}

@media (max-width: 768px) {
    .permissions-checkbox-group {
        padding: 4px;
    }

    .permission-checkbox-label {
        font-size: 14px;
    }
}

.dropdown-container {
    position: relative;
    width: 100%;
}

.dropdown-trigger {
    width: 100%;
    padding: 8px 12px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-trigger:hover {
    background-color: #f8f9fa;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 4px;
    padding: 8px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-height: 200px;
    overflow-y: auto;
}

.permission-checkbox-label {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.permission-checkbox-label:hover {
    background-color: #f8f9fa;
}

.selected-permissions {
    margin-top: 8px;
    font-size: 0.9em;
    color: #666;
    word-break: break-word;
}

/* Close dropdown when clicking outside */
.dropdown-content.show {
    display: block;
}

@media (max-width: 768px) {
    .dropdown-content {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        max-width: 300px;
    }
}

.multi-select-dropdown {
    position: relative;
    width: 100%;
    font-size: 14px;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
}

.dropdown-header:hover:not(.disabled) {
    background-color: #f8f9fa;
}

.dropdown-header.disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

.dropdown-arrow {
    font-size: 12px;
    color: #666;
}

.dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-option {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-option:hover {
    background-color: #f8f9fa;
}

.dropdown-option input[type="checkbox"] {
    margin-right: 8px;
}

.selected-items {
    margin-top: 8px;
    padding: 4px 8px;
    font-size: 0.9em;
    color: #666;
    background-color: #f8f9fa;
    border-radius: 4px;
    word-break: break-word;
}

@media (max-width: 768px) {
    .dropdown-options {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 300px;
        max-height: 80vh;
        background: white;
        z-index: 9999;
        box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    }

    .dropdown-options::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

    .dropdown-option {
        padding: 12px 16px;
        min-height: 44px;
    }

    .dropdown-option input[type="checkbox"] {
        width: 20px;
        height: 20px;
    }
}

.table-container {
    flex: 1;
    overflow-x: auto;
}

.tabs {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.tab {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
}

.tab.active {
    border-bottom: 2px solid #007bff;
    color: #007bff;
}

.customer-profile {
    max-width: 800px;
    margin: 0 auto;
}

.profile-details {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.edit-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.edit-form input,
.edit-form textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.button-group {
    display: flex;
    gap: 10px;
}

.back-button {
    margin-bottom: 20px;
    padding: 8px 16px;
    background: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.customer-orders {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.customer-orders table {
    width: 100%;
    border-collapse: collapse;
}

.customer-orders th,
.customer-orders td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.btn-view {
    padding: 6px 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-view:hover {
    background: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
    .customer-orders {
        overflow-x: auto;
    }
    
    .edit-form input,
    .edit-form textarea {
        width: 100%;
    }
}

.table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

.users-table {
    min-width: 100%;
    width: 100%;
    border-collapse: collapse;
}

@media (max-width: 768px) {
    .users-table td {
        padding: 8px 8px 8px 45%;
        min-height: 44px;
        position: relative;
        border-bottom: 1px solid #ddd;
    }

    .users-table td::before {
        content: attr(data-label);
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        padding-right: 10px;
        font-weight: bold;
        text-align: left;
    }

    .btn-view {
        width: auto;
        min-width: 100px;
        margin: 4px 0;
    }
}

.manage-users {
    width: 100%;
    max-width: 100%;
    padding: 15px;
    margin: 10px auto;
    box-sizing: border-box;
}
  