.skeleton-table {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.skeleton-table-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 16px;
  padding: 12px 0;
  border-bottom: 2px solid #eee;
  margin-bottom: 16px;
}

.skeleton-table-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 16px;
  padding: 12px 0;
  border-bottom: 1px solid #eee;
}

.skeleton-header-cell {
  width: 100%;
}

.skeleton-table-cell {
  width: 100%;
}

@media (max-width: 768px) {
  .skeleton-table {
    padding: 12px;
  }

  .skeleton-table-row {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px;
    padding: 16px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }

  .skeleton-table-header {
    display: none;
  }
} 