.product-selection {
  padding: 20px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-color: #4CAF50;
}

.product-card.selected {
  border-color: #4CAF50;
  background-color: #f1f8e9;
  box-shadow: 0 4px 8px rgba(76, 175, 80, 0.2);
}

.product-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.product-card h3 {
  margin: 10px 0;
  font-size: 1em;
  text-align: center;
  color: #000;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  width: 100%;
}

.product-card.selected h3 {
  color: #1a5e1d;
  font-weight: bold;
}

.sku-selection {
  margin-top: 20px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.sku-table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px 0;
}

.sku-table th,
.sku-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.sku-table th {
  background-color: #f5f5f5;
}

.sku-table input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.sku-actions {
  text-align: right;
  margin-top: 15px;
}

.navigation-buttons {
  display: none;
}

.btn-next {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-next:hover {
  background-color: #45a049;
} 